<template>
  <div>
    <div class="scientificPublication" v-if="!blockEditingInProcess">
      <div
        :style="!isExpert && !isManager ?
        'position: absolute; top: 18px; right: 14px' : 'position: absolute; top: 10px; right: 14px'">
        <!--        v-if="konvertConvert && (isExpert || isManager)"-->
        <button
          class="usual-btn" style="padding: 5px 34px;" @click="editRowExpert"
          v-if="konvertConvert && isManager"
        >
          Балл
        </button>
        <button class="usual-btn usual-btn_disabled" v-else-if="applicantBlocked && (!isExpert || isManager)">Изменить</button>
        <button class="usual-btn" @click="editRow" v-else-if="!applicantBlocked && (!isExpert || isManager)">Изменить</button>
        <button class="usual-btn" @click="cancelEditRow" v-else-if="(!isExpert || isManager)">Отменить</button>
        <v-dialog
          v-model="deleteConfirmDialog"
          width="375"
        >
          <v-card>
            <v-card-title>Подтверждение удаления строки</v-card-title>
            <v-card-actions style="justify-content: center">
              <v-btn class="dialog-btn" @click="deleteRow">Да</v-btn>
              <v-btn class="dialog-btn" @click="deleteConfirmDialog = false">Нет</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="curatorBlockEditingInProcess"
          width="500"
        >
          <v-card>
            <v-card-title>Введите балл</v-card-title>
            <v-card-actions style="justify-content: center">
              <div class="expertBall">
                <div class="inputDiv">
                  <label>Балл</label>
                  <v-text-field
                    v-model="value.ballBallExpert"
                  ></v-text-field>
                </div>
                <p class="bold" style="margin: 15px auto 0 5px">Комментарий</p>
                <textarea
                  class="textarea"
                  v-model="value.ballCommentExpert"
                ></textarea>
                <v-btn class="dialog-btn" style="margin-top: 25px; margin-bottom: 15px;"
                       @click="expertBallUpdate(value.id, value.ballBallExpert, value.ballCommentExpert)">Сохранить
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div v-if="konvertConvert && (isExpert || isManager)" class="applicantsSelectionJsonOrgScore">
          <p style="margin-top: 3px;" v-if="(value.ballBallMethod + value.ballBallExpert > 0) && (!isExpert || isManager)">
            {{ value.ballBallMethod }}<span style="font-size: 14px; line-height: 16px;">методика</span></p>
          <p style="margin-top: 3px;" v-if="value.ballBallMethod + value.ballBallExpert > 0">
            {{ value.ballBallExpert }}<span style="font-size: 14px; line-height: 16px;">комиссия</span>
            <span v-if="value.ballCommentExpert && value.ballCommentExpert !== 'null'" :data-tooltip="`${value.ballCommentExpert}`"
                  class="expertBall-info"></span>
          </p>
        </div>
      </div>
      <div class="textInfoDiv" style="margin-top: 0; max-width: 1000px">
        <span class="order">{{ order + 1 }}</span>
        <p class="type">{{ areaComputed }}</p>
      </div>
      <p class="name" style="max-width: 1000px" v-html="value.articleEventName"></p>
      <p class="authors" style="margin-top: 5px;">{{ value.articleCoauthors ? `Соавторы: ${value.articleCoauthors}` : 'Соавторы: нет' }}</p>
      <div class="textInfoDiv">
        <p class="category" style="margin-right: 28px; max-width: 950px">{{ itemComputed }}</p>
        <div
          class="scan" v-if="value.sysScanFileUuid"
          @mouseout="hover.scan = false" @mouseover="hover.scan = true">
          <img v-if="hover.scan" src="@/assets/icons/stp_scan_orange.svg" class="scan__img" alt="scan">
          <img v-else src="@/assets/icons/stp_scan_blue.svg" class="scan__img" alt="scan">
          <p
            class="scan__text"
            @click="getFile(value.sysScanFileUuid, value.sysScanFileNameOrig, value.sysScanFileExt)"
          >
            Скан документа
          </p>
        </div>
      </div>
      <!--      <p class="authors" v-html="value.patentAuthors"></p>-->
    </div>
    <div v-if="blockEditingInProcess">
      <Block2Creation
        :rowID="rowID"
        :block2Data="value"
        @updateData="updateData"
      />
    </div>
  </div>
</template>

<script>
import Block2Creation from "@/components/applicants/applicantForm/mnsBlocks/blockCreation/Block2Creation";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Block2Item",
  components: {Block2Creation},
  data() {
    return {
      blockEditingInProcess: false,
      curatorBlockEditingInProcess: false,
      // expertBall: null,
      // expertComment: null,
      deleteConfirmDialog: false,
      hover: {
        scan: false,
        url: false,
      },
      eventYearArray: [
        {text: '2021', value: 2021},
        {text: '2022', value: 2022},
        {text: '2023', value: 2023},
      ],
    }
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      },
    },
    eventAreaArray: {
      type: Array,
      default: function () {
        return []
      },
    },
    eventTypeArray: {
      type: Array,
      default: function () {
        return []
      },
    },
    eventStatusArray: {
      type: Array,
      default: function () {
        return []
      },
    },
    eventMonthsArray: {
      type: Array,
      default: function () {
        return []
      },
    },
    rowID: {
      type: String,
      default: ''
    },
    order: {
      type: Number,
      default: 0
    },
    applicantBlocked: {
      type: Boolean,
      default: false,
    },
    konvertConvert: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      isExpert: 'isExpert',
      // isCurator: 'isCurator',
      isManager: 'isManager'
    }),
    
    areaComputed() {
      return `${this.eventAreaArray.filter(el => el.value === this.value.articleArea)[0]?.text}`
    },
    
    itemComputed() {
      return `${this.value.articleEventTypeStr ? this.value.articleEventTypeStr : this.eventTypeArray.filter(el => el.value === this.value.articleEventType)[0]?.text} •
      ${this.eventStatusArray.filter(el => el.value === this.value.articleEventStatus)[0]?.text} •
      ${this.eventYearArray.filter(el => el.value === this.value.articleYearStart)[0]?.text}
      ${this.eventMonthsArray.filter(el => el.value === this.value.articleMonthStart)[0]?.text} —
      ${this.eventYearArray.filter(el => el.value === this.value.articleYearEnd)[0]?.text}
      ${this.eventMonthsArray.filter(el => el.value === this.value.articleMonthEnd)[0]?.text}`
    }
  },
  methods: {
    editRow() {
      this.blockEditingInProcess = true
    },
    
    editRowExpert() {
      this.curatorBlockEditingInProcess = true
    },
    
    cancelEditRow() {
      this.blockEditingInProcess = false;
      this.$emit("updateData");
    },
    
    updateData() { // вызов getAll в корневом файле
      this.$emit("updateData");
    },
    
    deleteConfirmFunc() {
      this.deleteConfirmDialog = true;
    },
    
    async deleteRow() {
      try {
        const res = await axios.delete(`/api/2022/mns/block4/delete?rowId=${this.value.id}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          console.log('deleteRow успех');
          this.$emit("updateData");
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async expertBallUpdate(rowId, ball, comment) {
      let url = `/api/2022/mns/expertBall/block4`;
      const formData = new FormData();
      formData.append('rowId', rowId);
      formData.append('ball', ball);
      formData.append('comment', comment);
      try {
        const res = await axios.post(url, formData,
          {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.value.ballBallExpert = ball;
          this.value.ballCommentExpert = comment;
          // this.$emit("updateData"); // вызов getAll в корневом файле
          this.curatorBlockEditingInProcess = false;
          console.log('expertBallUpdate успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async getFile(id, name, ext) {
      let uri = `/api/2022/mns/blocks/scan/download?sysScanFileUuid=${id}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`}
      })
      this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext)
    },
    
    linkCheck(link) {
      if (link.slice(0, 4) === 'http') {
        return link
      } else {
        return `http://${link}`
      }
    },
    
    saveByteArray(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'; // вариант открытия в новом окне
      // link.download = reportName; // вариант скачивания
      link.click();
    },
  }
}
</script>

<style scoped>

</style>