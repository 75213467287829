import { render, staticRenderFns } from "./Block1Creation.vue?vue&type=template&id=dc3dc204&scoped=true&"
import script from "./Block1Creation.vue?vue&type=script&lang=js&"
export * from "./Block1Creation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc3dc204",
  null
  
)

export default component.exports