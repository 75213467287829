import { render, staticRenderFns } from "./Block3Creation.vue?vue&type=template&id=127d53ae&scoped=true&"
import script from "./Block3Creation.vue?vue&type=script&lang=js&"
export * from "./Block3Creation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127d53ae",
  null
  
)

export default component.exports