<template>
  <!--  :style="creationData.length > 0 ? '' : 'border-bottom: none'"-->
  <div class="scientificPublication" style="padding-top: 4px; ">
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 365px">
        <label>Область достижения <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.articleArea"
          :items="articleAreaArray"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 365px">
        <label>Тип публикации <span style="color: red">*</span></label>
        <!--        @change="getSprStatus"-->
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.articlePubType"
          :items="articlePubTypeArray"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 365px; margin-right: 0">
        <label>Статус издания <span style="color: red">*</span></label>
        <!--        @change="journalCategory"-->
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.articlePubStatus"
          :items="articlePubStatusArray"
        ></v-autocomplete>
      </div>
    </div>
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 1145px; margin-right: 0">
        <label>Библиографическое описание <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.articleLink"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
    </div>
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 755px">
        <label>Наличие соавторов <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          v-model="areArticleCoauthors"
          :items="articleCoauthorsOptions"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 170px">
        <label>Год публикации <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.articlePubYear"
          :items="articlePubYearArray"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 170px; margin-right: 0">
        <label>Месяц публикации <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          v-model="creationData.articlePubMonth"
          :items="articlePubMonthsArray"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-autocomplete>
      </div>
    </div>
    <div class="scientificPublication__row" v-if="areArticleCoauthors">
      <div class="inputDiv" style="width: 755px">
        <label>Соавторы <span style="color: red">*</span></label>
        <v-text-field
          v-model="creationData.articleCoauthors"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
    </div>
    <v-dialog
      v-model="invalidData"
      width="500px"
    >
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ 'Требуется заполнить все обязательные поля' }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="wrongFileTypeDialog"
      width="665px"
    >
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ 'Загруженный формат скана не соответствует расширению .pdf' }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteConfirmDialog"
      width="375"
    >
      <v-card>
        <v-card-title>Подтверждение удаления строки</v-card-title>
        <v-card-actions style="justify-content: center">
          <v-btn class="dialog-btn" @click="deleteRow">Да</v-btn>
          <v-btn class="dialog-btn" @click="deleteConfirmDialog = false">Нет</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="fileDiv">
      <v-file-input
        prepend-icon="mdi-folder-open-outline" v-model="creationData.scanIdSysScan"
        accept="application/pdf" :style="creationData.sysScanFileUuid ? 'display: none' : ''"
        placeholder="Выберите файл"
        @change="wrongFileTypeDialogFunc($event)"
      ></v-file-input>
      <div class="file__info">
        <p class="file__text" v-if="!creationData.sysScanFileUuid">Скан-копия публикации</p>
        <p
          class="file__text file__text_link" v-else
          @click="getFile(creationData.sysScanFileUuid, creationData.sysScanFileNameOrig, creationData.sysScanFileExt)">
          Скан-копия публикации
        </p>
        <p class="file__status" v-if="creationData.scanIdSysScan">Загружен</p>
        <p class="file__status file__status_empty" v-else>Не сохранено</p>
      </div>
      <img
        v-if="creationData.sysScanFileUuid" src="@/assets/icons/close-red-round.svg"
        alt="delete" style="margin-left: 20px;" class="fileDiv__delete" @click="deleteFile(creationData.id)">
    </div>
    <div class="btnDiv" style="padding: 22px 0 0 0; border-top: none">
      <button
        class="usual-btn" :disabled="!blockEditingInProcess" @click="updateInfo"
      >
        Сохранить
      </button>
      <button class="usual-btn usual-btn_grey" @click="getAllBlock">Отмена</button>
      <p class="block__hint"><span style="color: red">*</span> Все поля, помеченные звездочкой, обязательны для
        заполнения</p>
      <button
        v-if="block1Data.id"
        class="usual-btn usual-btn_delete" style="margin-left: auto" @click="deleteConfirmFunc"
      >
        Удалить
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Block1Creation",
  data() {
    return {
      loading: false,
      invalidData: false,
      blockEditingInProcess: true,
      deleteConfirmDialog: false,
      areArticleCoauthors: null,
      wrongFileTypeDialog: false,
      wrongFileType: false,
      creationData: {
        id: 0,
        articlePubType: null,
        articlePubStatus: null,
        articleArea: null,
        articleLink: "", //Библиографическая ссылка *
        articleCoauthors: "",
        articlePubYear: null, //2021 2022 2023
        articlePubMonth: "",
        scanIdSysScan: null,
      },
      articlePubYearArray: [
        {text: '2021 год', value: 2021},
        {text: '2022 год', value: 2022},
        {text: '2023 год', value: 2023},
      ],
      articleCoauthorsOptions: [
        {text: 'Да', value: true},
        {text: 'Нет', value: false},
      ],
      articlePubMonthsArray: [],
      articlePubTypeArray: [],
      articlePubStatusArray: [],
      articleAreaArray: [],
      rules: {
        required: value => !!value || 'Обязательное поле!',
      }
    }
  },
  props: {
    rowID: {
      type: String,
      default: ''
    },
    specID: {
      type: Number,
      default: 0
    },
    block1Data: {
      type: Object,
      default: function () {
        return {}
      },
    },
    applicantBlocked: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
    }),

    fieldsValidation() {
      if (this.creationData.articleArea && this.creationData.articleLink && this.creationData.articlePubType
        && this.creationData.articlePubStatus && this.creationData.articlePubYear && this.creationData.articlePubMonth
        && (((this.areArticleCoauthors === true && this.creationData.articleCoauthors)) || this.areArticleCoauthors === false)) {
        return true
      } else {
        return false
      }
    },
  },

  created() {
    this.createdFunc();
  },

  methods: {
    async createdFunc() {
      await this.getSprArea();
      await this.getSprPubType();
      await this.getSprStatus();
      await this.getSprMonths();

      if (this.block1Data.id) { // проверка на создание/редактирование
        // await this.getSprStatus();
        this.creationData = this.block1Data;
      }

      if (this.block1Data.articleCoauthors) {
        this.areArticleCoauthors = true;
      }
    },
  
    wrongFileTypeDialogFunc(arg) {
      if (arg.type !== 'application/pdf') {
        this.wrongFileTypeDialog = true;
        this.wrongFileType = true;
      } else {
        this.wrongFileType = false;
      }
    },

    async getSprArea() {
      this.articleAreaArray = [];
      try {
        const res = await axios.get(`/api/2022/spr/textValMns/get?block=1&position=1`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.articleAreaArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprArea успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getSprPubType() {
      this.articlePubTypeArray = [];
      try {
        const res = await axios.get(`/api/2022/spr/textValMns/get?block=1&position=2`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.articlePubTypeArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprPubType успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getSprStatus() {
      this.articlePubStatusArray = [];
      // this.creationData.articlePubStatus = null;
      try {
        const res = await axios.get(`/api/2022/spr/textValMns/get?block=1&position=3`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.articlePubStatusArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprStatus успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getSprMonths() {
      this.articlePubMonthsArray = [];
      try {
        const res = await axios.get(`/api/2022/spr/textValMns/get?block=1&position=6`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.articlePubMonthsArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprMonths успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async updateInfo() {
      if (this.fieldsValidation && !this.wrongFileType) {
        let url = `/api/2022/mns/block1/add`;
        if (this.creationData.id !== 0) {
          url = `/api/2022/mns/block1/update`
        }
        const formData = new FormData();
        if (this.creationData.scanIdSysScan && typeof this.creationData.scanIdSysScan !== "number") {
          formData.append('file', this.creationData.scanIdSysScan);
        }
        formData.append('data', new Blob([JSON.stringify({
          "id": this.creationData.id !== 0 ? this.creationData.id : '',
          "mainId": this.rowID,
          "articleArea": this.creationData.articleArea,
          "articlePubType": this.creationData.articlePubType,
          "articlePubStatus": this.creationData.articlePubStatus,
          "articleCoauthors": this.areArticleCoauthors ? this.creationData.articleCoauthors : '',
          "articleLink": this.creationData.articleLink,
          "articlePubYear": this.creationData.articlePubYear,
          "articlePubMonth": this.creationData.articlePubMonth,
        })], {
          type: "application/json"
        }));
        try {
          const res = await axios.post(url, formData,
            {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
          if (res.status === 200 && res.data) {
            this.$emit("updateData"); // вызов getAll в корневом файле
            this.blockEditingInProcess = false;
            this.loading = false;
            console.log('updateInfo успех');
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      } else if (this.wrongFileType) {
        this.wrongFileTypeDialog = true
      } else {
        this.invalidData = true
      }
    },

    getAllBlock() {
      this.$emit("updateData");
    },

    deleteConfirmFunc() {
      this.deleteConfirmDialog = true;
    },

    async deleteRow() {
      try {
        const res = await axios.delete(`/api/2022/mns/block1/delete?rowId=${this.block1Data.id}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          console.log('deleteRow успех');
          this.$emit("updateData");
        }
      } catch (e) {
        console.log(e);
      }
    },

    async deleteFile(ID) {
      this.loading = true;
      let uri = `/api/2022/mns/block1/scan/delete?rowId=${ID}`;
      try {
        const res = await axios.delete(uri, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.creationData.scanIdSysScan = null;
          this.creationData.sysScanFileUuid = null;
          this.loading = false;
          console.log('deleteFile успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async getFile(id, name, ext) {
      let uri = `/api/2022/mns/blocks/scan/download?sysScanFileUuid=${id}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`}
      })
      this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext)
    },

    saveByteArray(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'; // вариант открытия в новом окне
      // link.download = reportName; // вариант скачивания
      link.click();
    },
  }
}
</script>

<style scoped>

</style>